import * as React from "react"

import Footer from '../componets/NotFoundPage/Footer';

import '../assets/sass/main.scss'
import Header from "../componets/Template/Header";

// markup
const IndexPage = () => {
  return (
    <main>
      <Header title={'404 page not found'} />
      <header className="header-main-page">
        <div className="center-section">
          <div className="center-padding">
            <div className="page-not-found">
              <h1 className="title">404</h1>
              <div className="title-text">
                Page not found
              </div>
            </div>
          </div>
        </div>
      </header>
      <Footer />
    </main>
  )
}

export default IndexPage
