import * as React from "react";
import FooterMenu from "../MainPage/FooterMenu";
export default function() {
    return <footer className="page-not-found-footer">
              <div className="footer-dark">
                <div className="clear"></div>
                <div className="center-section footer-feedback">
                  <div className="center-padding">
                    <FooterMenu />
                  </div>
                </div>
              </div>
           </footer>;
}
